import React from 'react';
import styled from 'styled-components';
import { FaExternalLinkAlt } from 'react-icons/fa';

const AboutSection = styled.section`
  color: ${({ theme }) => theme.text};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 40px;
  margin-bottom: 40px;

  @media(min-width: 768px) {
    flex-direction: row;
    padding: 40px 40px;
    padding-right: 60px
  }

  .about-content {
    flex: 1;
    text-align: center;

    @media(min-width: 768px) {
      text-align: left;
    }

    h2 {
      font-size: 2.5rem;
      color: ${({ theme }) => theme.sectionTitle};
      margin-bottom: 20px;
    }

    p {
      line-height: 1.6;
      margin-bottom: 15px;
    }
  }

  .banner-image {
    flex: 1;
    text-align: center;
    padding-bottom: 40px;

    @media(min-width: 768px) {
      text-align: right;
      padding-right: 50px;
      padding-bottom: 0px;
    }

    img {
      width: 100%;
      max-width: 540px;
      height: auto;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  }
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 20px;
  text-align: center;
`;

const SelectionCard = styled.a`
  background-color: ${({ theme }) => theme.tagBackground};
  color: ${({ theme }) => theme.text};
  padding: 12px 10px;
  border: none;
  cursor: pointer;
  display: inline-block;
  margin-top: 0px;
  transition: all 0.3s ease;
  border-radius: 0;
  text-decoration: none;
  font-size: 14px;
  height: 45px;

  &:hover {
    border-bottom: 3px solid ${({ theme }) => theme.titleText};
    background-color: ${({ theme }) => theme.tagBackground};
    color: ${({ theme }) => theme.text};
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

const LiveTag = styled.a`
  cursor: pointer;
`;

const About = ({ theme, scrollToSection }) => {
  return (
    <div>

      <AboutSection id="about">
        <div className="banner-image">
          <img src={'/images/fss.png'} alt="Event banner" />
        </div>
        <div className="about-content">
          
          <h3 style={{marginBottom: '10px'}}>Thank You 2024! ❤️</h3>
          <p>
            We would like to thank all the pilots who made the 2024 event truly unforgettable.
            There was a good amount of traffic across all three airports, and the LIVE gathering in Finland was a resounding success!
          </p>
          <p>
            <b>EFRO</b> 148 arrivals | <b>ENTC</b> 88 arrivals | <b>ESNQ</b> 40 arrivals
          </p>
          <p>
            We look forward to seeing your flights in the Scandinavian skies during the next edition of Fly and See Santa!
          </p>

        </div>
        
      </AboutSection>

    </div>
  );
};

/*

          <p>
            Fly and See Santa, the largest annual Christmas event in VATSIM skies, takes place at three airports in the northernmost reaches of Europe. Experience the magic of snow-covered landscapes and icy runways as you arrive at Rovaniemi, Tromsø, and Kiruna—airports nestled in the stunning wilderness of northern Europe. Throughout December, the airspace buzzes with flights full of passengers eager to visit Santa's winter wonderland. We hope you’ll join us for this unforgettable event!
          </p>

before AboutSection:

      <ButtonContainer>
        <SelectionCard
          href="https://booking.vatsim-scandinavia.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Bookings <FaExternalLinkAlt style={{ fontSize: '12px', marginLeft: '6px' }} />
        </SelectionCard>
        <SelectionCard as="button" onClick={() => scrollToSection('Routes')}>
          Routes
        </SelectionCard>
        <SelectionCard as="button" onClick={() => scrollToSection('Notes')}>
          Pilot Briefing
        </SelectionCard>
        <SelectionCard as="button" onClick={() => scrollToSection('Sceneries')}>
          Sceneries
        </SelectionCard>
      </ButtonContainer>


*/

// <LiveTag onClick={() => scrollToSection('Live')}>Follow us LIVE!</LiveTag> (after <p>)

export default About;
